<template>
  <header class="mm-supplier-header">
    <HeaderGodMode v-if="mpAdminInfoEmail" />
    <div class="mm-supplier-header__container">
      <div class="mm-supplier-header__logo logo">
        <NuxtLink to="/">
          <BaseImg
            :src="logoSrc"
            alt=""
            class="logo--desc-off"
          />
        </NuxtLink>
      </div>
      <ClientOnly>
        <ul
          v-if="isHeaderMenuRendered"
          class="mm-supplier-header__menu"
        >
          <li
            v-for="link in supplierMenu"
            :key="link.label"
            class="mm-supplier-header__menu-item"
          >
            <NuxtLink
              :to="link.path"
              :class="{
                'mm-supplier-header__menu-link': true,
                'mm-supplier-header__menu-link--active': isActiveLink(link),
                'mm-supplier-header__menu-link--disabled': link?.disabled,
              }"
            >
              {{ link.label }}
            </NuxtLink>
          </li>
        </ul>
      </ClientOnly>
      <div class="mm-supplier-header__account mm-caption-regular">
        <div class="mm-supplier-header__account-icon">
          <LoginButton />
        </div>
      </div>

      <div class="header__menu--mobile-top">
        <SvgIcon src="navigation/menu" />
      </div>
    </div>
    <MobileMenu />
  </header>
</template>

<script setup lang="ts">
import LoginButton from 'components/LoginButton.vue';
import { MenuHelper } from 'utils/menuHelper.util';
import { RouterHelper } from 'shared/utils/routerHelper.util';
import IMenuLinkItem from 'shared/models/linkItem.model';
import { useUserStore } from 'store/user.store';
import MobileMenu from 'components/MobileMenu.vue';
import { storeToRefs } from 'pinia';
import BaseImg from 'shared/components/BaseImg.vue';

const userStore = useUserStore();
const supplierMenu = computed(() => MenuHelper.getHeaderLinks(userStore?.groups));
const $route = useRoute();
const logoSrc = '/images/logo-desc-off.svg';

const { mpAdminInfoEmail } = storeToRefs(useUserStore());

const isHeaderMenuRendered = computed(() =>
  supplierMenu.value
    .map((menuitem) => menuitem.path)
    .includes($route.path) ||
  [
    '/supplier/catalogs/',
    '/supplier/orders',
    '/supplier/order',
    '/supplier/settings/',
    '/supplier/qualifications',
    '/supplier/needs',
  ].some((path) => RouterHelper.includePath(path, $route)),
);

function isActiveLink(linkItem: IMenuLinkItem) {
  return (linkItem.path?.includes('order') && $route.fullPath?.includes('order')) || $route.fullPath?.includes(linkItem.path)
    || MenuHelper.isActive(linkItem);
}
</script>

<style scoped lang="scss">
@import 'styles/base/common/variables';

$root: '.mm-supplier-header';

.mm-supplier-header {
  width: 100%;

  .mm-god-mode-header {
    max-width: 1600px;
    margin: 0 auto;
  }

  &__container {
    padding: 32px 40px 0;
    display: flex;
    max-width: 1600px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__logo {
    flex: 1;
  }

  &__account {
    display: flex;
    flex: 1;
    align-content: center;
    justify-content: flex-end;
    padding-top: 4px;
    font-size: 14px;
    line-height: 22px;
    margin-left: auto;
  }

  &__menu {
    display: flex;
    align-self: flex-end;
    padding: 0;
    margin: 0;
    flex: 1;
  }

  &__container {
    .header__menu--mobile-top {
      display: none;
    }
  }

  &__menu-item {
    display: flex;
    list-style-type: none;

    &:not(:first-child) {
      margin-left: 28px;
    }
  }

  &__menu-link {
    display: flex;
    padding: 4px 12px 36px;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    height: 100%;
    text-decoration: none;
    cursor: pointer;
    color: $light-green;
    font-weight: 500;

    &--active {
      color: $text-black;
      font-weight: 500;

      &::after {
        display: block;
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        z-index: 10;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        width: 100%;
        height: 3px;
        background: $main-green;
      }
    }

    &--disabled {
      pointer-events: none;
      cursor: not-allowed;
      color: $text-disabled;
    }
  }

  &__account-icon {
    cursor: pointer;

    :deep(svg) {
      margin-left: 16px;
      width: 24px;
      height: 16px;
    }
  }
}

@media screen and (max-width: 1279px) {
  .mm-supplier-header {
    &__menu {
      margin-left: 217px;

      &-item a {
        padding-bottom: 20px;
      }

      &-item:first-child a {
        padding-left: 0;
      }

      &-item:last-child a {
        padding-right: 0;
      }
    }

    &__logo img {
      width: 121px;
    }

    &__container {
      padding: 32px 32px 0;

      .header__menu--mobile-top {
        height: 60px;
        margin-left: auto;
        display: block;
      }
    }

    .header--mobile {
      box-shadow: none;
      border: 1px solid $gray-200;
      z-index: 11;

      .header__item {
        height: 56px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .mm-supplier-header {
    padding: 0 28px !important;

    &__menu {
      display: none;
    }

    &__account {
      display: none;
    }

    &__menu {
      margin-left: 150px;
    }

    &__container {
      padding: 20px 0 0;

      .header__menu--mobile-top {
        display: block;
        height: unset;
        margin-bottom: 22px;
        margin-left: auto;
      }
    }
  }
}
</style>
